





























import { Component, Vue, Watch } from "vue-property-decorator";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { SnackbarInterface } from "@/types/snackbar";

@Component
export default class Snackbar extends Vue {
  // Data
  private show: boolean = false;
  private snackData: SnackbarInterface = SnackbarModule.snack;

  get snackState(): SnackbarInterface {
    return SnackbarModule.snack;
  }

  @Watch("snackState", { immediate: false, deep: true })
  private onStateChange(snackState: SnackbarInterface) {
    let color = "";
    switch (this.snackState.color) {
      case "success":
        color = "#17B1BE";
        break;
      case "error":
        color = "#E72339";
        break;
      case "warning":
        color = "#FFC969";
        break;
    }
    if (snackState.message && snackState.message !== "") {
      const snakeBarElement = document.querySelector("#snackbar > .v-sheet.v-snack__wrapper")!;
      snakeBarElement.setAttribute("style", `border-color: ${color} !important;`);
      this.show = true;
    }
  }

  @Watch("show", { immediate: false, deep: true })
  private onShowChange(show: boolean) {
    if (!show) {
      this.snackState.message = "";
    }
  }
}
