import { CUProjectPositionInterface, ProjectPositionInterface } from "@/types/project.type";
import axios from "@/plugins/axios-setting";

export function getProjectPositions(): Promise<ProjectPositionInterface[]> {
  return axios.get("/api/project-position/");
}

export function createProjectPosition(projectPosition: CUProjectPositionInterface): Promise<ProjectPositionInterface> {
  return axios.post("/api/project-position/", projectPosition);
}

export function updateProjectPosition(projectPosition: CUProjectPositionInterface): Promise<ProjectPositionInterface> {
  return axios.patch(`/api/project-position/${projectPosition.id}/`, projectPosition);
}

export function deleteProjectPosition(id: number): Promise<any> {
  return axios.delete(`/api/project-position/${id}/`);
}
export function getPositionsByProject(id: number): Promise<ProjectPositionInterface[]> {
  return axios.get(`/api/project-position/?project=${id}`);
}
