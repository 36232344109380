
















import { Component, Vue } from "vue-property-decorator";
import { ErrorModule } from "@/store/modules/error";

@Component
export default class NotFound extends Vue {
  private async goToHome() {
    await ErrorModule.errorNotFound(false);
    this.$router.push({ name: "Timesheet" });
  }
}
