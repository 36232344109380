import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import {
  CUProjectPositionInterface,
  PositionInterface,
  ProjectInterface,
  ProjectListInterface,
  ProjectPaginationInterface,
  ProjectPositionInterface,
} from "@/types/project.type";
import {
  getProjects,
  getUserProjects,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
  getProjectListRequest,
} from "@/services/project.service";
import store from "@/store";
import { getPositions } from "@/services/position.service";
import {
  createProjectPosition,
  deleteProjectPosition,
  updateProjectPosition,
} from "@/services/project-position.service";
import { DataTableHeader } from "vuetify";
import { ProjectMemberInterface } from "@/types/project-member.type";
import { DataTableOption } from "@/types/data-table.type";

interface IProjectModule {
  projects: ProjectInterface[];
  project: ProjectInterface | {};
  positions: PositionInterface[];
  projectPositions: ProjectPositionInterface[];
  projectMembers: ProjectMemberInterface[];
}

@Module({
  dynamic: true,
  name: "project",
  store,
})
class Project extends VuexModule implements IProjectModule {
  public projects: ProjectInterface[] = [];
  public totalProject: number = 0;
  public userProjects: ProjectInterface[] = [];
  public project: ProjectInterface = {} as ProjectInterface;
  public projectList: ProjectListInterface[] = [];
  public positions: PositionInterface[] = [];
  public projectPositions: ProjectPositionInterface[] = [];
  public projectMembers: ProjectMemberInterface[] = [];
  public projectHeaders: DataTableHeader[] = [
    {
      text: "",
      value: "alertCount",
    },
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Client",
      value: "clientDisplay",
      sortable: false,
    },
    {
      text: "Project Type",
      value: "projectTypeDisplay",
      sortable: false,
    },
    {
      text: "Total Duration",
      value: "totalDuration",
      sortable: false,
    },
    {
      text: "Approved Duration",
      value: "approvedDuration",
      sortable: false,
    },
    {
      text: "Modified",
      value: "updated",
    },
    {
      text: "Active",
      value: "active",
    },
    {
      text: "Action",
      value: "action",
      align: "center",
    },
  ];

  @MutationAction({ mutate: ["projects", "totalProject"], rawError: true })
  public async getProjects(options?: DataTableOption) {
    const projects: ProjectPaginationInterface = await getProjects(options);
    return { projects: projects.results, totalProject: projects.count };
  }

  @MutationAction({ mutate: ["userProjects", "totalProject"], rawError: true })
  public async getUserProjects(options?: DataTableOption) {
    const userProjects: ProjectPaginationInterface = await getUserProjects(options);
    if (!userProjects.results) {
      return { userProjects, totalProject: 0 };
    }
    this.userProjects = userProjects.results;
    return { userProjects: userProjects.results, totalProject: userProjects.count };
  }

  @MutationAction({ mutate: ["projectList"], rawError: true })
  public async getProjectList(me = false) {
    const projectList: ProjectListInterface[] = await getProjectListRequest(me);
    return { projectList };
  }

  @MutationAction({ mutate: ["projects"], rawError: true })
  public async addProject(project: ProjectInterface) {
    await createProject(project);
    const projects: ProjectPaginationInterface = await getProjects();
    return { projects: projects.results };
  }

  @MutationAction({ mutate: ["project", "projectPositions", "projectMembers"], rawError: true })
  public async getProjectById(id: number) {
    const { projectPositions, projectMembers, ...project }: ProjectInterface = await getProjectById(id);
    return { project, projectPositions, projectMembers };
  }

  @MutationAction({ mutate: ["project"], rawError: true })
  public async updateProject(project: ProjectInterface) {
    const updatedProject: ProjectInterface = await updateProject(project);
    return { project: updatedProject };
  }

  @MutationAction({ mutate: ["projects", "project"], rawError: true })
  public async deleteProject(id: number) {
    await deleteProject(id);
    const projects: ProjectInterface[] = [...(this.state as IProjectModule).projects];
    const deletedIndex: number = projects.findIndex((item) => item.id === id);
    projects.splice(deletedIndex, 1);
    return { projects, project: null };
  }

  @MutationAction({ mutate: ["positions"], rawError: true })
  public async getPositions() {
    const positions: PositionInterface[] = await getPositions();
    return { positions };
  }

  @MutationAction({ mutate: ["projectPositions"], rawError: true })
  public async addProjectPosition(projectPosition: CUProjectPositionInterface) {
    const newProjectPosition: ProjectPositionInterface = await createProjectPosition(projectPosition);
    const projectPositions: ProjectPositionInterface[] = [...(this.state as IProjectModule).projectPositions];
    projectPositions.push(newProjectPosition);
    return { projectPositions };
  }

  @MutationAction({ mutate: ["projectPositions"], rawError: true })
  public async updateProjectPosition(projectPosition: CUProjectPositionInterface) {
    const updatedProjectPosition: ProjectPositionInterface = await updateProjectPosition(projectPosition);
    const projectPositions: ProjectPositionInterface[] = [...(this.state as IProjectModule).projectPositions];
    const updateIndex: number = projectPositions.findIndex((item) => item.id === updatedProjectPosition.id);
    projectPositions[updateIndex] = updatedProjectPosition;
    return { projectPositions };
  }

  @MutationAction({ mutate: ["projectPositions"], rawError: true })
  public async deleteProjectPosition(id: number) {
    await deleteProjectPosition(id);
    const projectPositions: ProjectPositionInterface[] = [...(this.state as IProjectModule).projectPositions];
    const deletedIndex: number = projectPositions.findIndex((item) => item.id === id);
    projectPositions.splice(deletedIndex, 1);
    return { projectPositions };
  }
}

export const ProjectModule = getModule(Project);
