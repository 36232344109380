import { FullDetailUserInterface } from "@/types/user.type";
import { ProjectModule } from "@/store/modules/project-module";

export function checkPermission(user: FullDetailUserInterface, pageName: string | null | undefined): boolean {
  if (user.role.name.toLowerCase() === "admin") {
    return true;
  } else if (user.role.name.toLowerCase() === "employee") {
    // console.log(pageName)
    const userProjects = ProjectModule.userProjects;
    switch (pageName) {
      case "Timesheet":
        return true;
      case "Leave Request":
        return true;
      case "Leave Request Calendar":
        return true;
      case "Project List":
        for (const p in userProjects) {
          if (userProjects[p].role?.toLowerCase() === "viewer" || userProjects[p].role?.toLowerCase() === "manager") {
            return true;
          }
        }
        break;
      case "Reports":
        for (const p in userProjects) {
          if (userProjects[p].role?.toLowerCase() === "manager") {
            return true;
          }
        }
        break;
      default:
        return false;
    }
  } else {
    // Guest
    return false;
  }
  return false;
}
