import { DataTableOption } from "@/types/data-table.type";

export function formatOrdering(options?: DataTableOption, customMap?: any): string {
  if (!options) {
    return "";
  }

  let result = "";
  if (options.sortBy.length > 0) {
    if (options.sortDesc[0]) {
      result += "-";
    }

    if (customMap && customMap[options.sortBy[0]]) {
      result += customMap[options.sortBy[0]];
    } else {
      result += options.sortBy[0];
    }
  }
  return result;
}
