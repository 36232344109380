import axios from "@/plugins/axios-setting";
import { ProjectInterface, ProjectListInterface, ProjectPaginationInterface } from "@/types/project.type";
import { DataTableOption } from "@/types/data-table.type";
import { formatOrdering } from "@/utils/datatableUtils";

export function getProjects(options?: DataTableOption): Promise<ProjectPaginationInterface> {
  let newOptions;
  if (options) {
    // format datatable options
    newOptions = {
      itemsPerPage: options.itemsPerPage,
      page: options.page,
      ordering: formatOrdering(options),
      search: options.search,
    };
  }

  return axios.get("/api/project/", { params: newOptions });
}

export function getProjectListRequest(me: boolean): Promise<ProjectListInterface[]> {
  return axios.get("/api/project-list/", { params: { me } });
}

export function getUserProjects(options?: DataTableOption): Promise<ProjectPaginationInterface> {
  let newOptions;
  if (options) {
    // format datatable options
    newOptions = {
      itemsPerPage: options.itemsPerPage,
      page: options.page,
      ordering: formatOrdering(options),
    };
  }

  return axios.get("/api/project/me/", { params: newOptions });
}

export function getProjectById(id: number): Promise<ProjectInterface> {
  return axios.get(`/api/project/${id}/`);
}

export function createProject(data: ProjectInterface): Promise<ProjectInterface> {
  return axios.post("/api/project/", data);
}

export function updateProject(data: ProjectInterface): Promise<ProjectInterface> {
  return axios.put(`/api/project/${data.id}/`, data);
}

export function deleteProject(id: number): Promise<void> {
  return axios.delete(`/api/project/${id}/`);
}

export function getProjectNotificationCount(): Promise<any> {
  return axios.get("/api/project-count-all/");
}
