import axios from "@/plugins/axios-setting";
import { CreatePositionInterface, PositionInterface } from "@/types/project.type";

export function getPositions(): Promise<PositionInterface[]> {
  return axios.get("/api/position/");
}

export function addPosition(dto: CreatePositionInterface): Promise<PositionInterface> {
  return axios.post("/api/position/", dto);
}
