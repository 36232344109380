import { getModule, Module, Mutation, Action, VuexModule } from "vuex-module-decorators";
import store from "@/store";

@Module({
  dynamic: true,
  name: "errorModule",
  store,
})
class ErrorPage extends VuexModule {
  public isNotFound: boolean = false;

  @Mutation
  public setNotFound(isError: boolean) {
    this.isNotFound = isError;
  }

  @Action({ commit: "setNotFound" })
  public errorNotFound(isError: boolean) {
    const isNotFound = isError;
    return isNotFound;
  }
}

export const ErrorModule = getModule(ErrorPage);
