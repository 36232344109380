import axios from "@/plugins/axios-setting";
import { LoginRequest, LoginResponse } from "@/types/auth.type";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";

export function login(data: LoginRequest): Promise<LoginResponse> {
  return axios.post("/api/token/", data);
}

export function refresh(data: { token: string }) {
  return axios.post("/api/token/refresh/", data);
}

export function getLoginUrl() {
  const uuid = uuidv4();
  Cookies.set("MSAL_STATE", uuid);
  return axios.get(`/api/msal/login_url?state=${uuid}`);
}

export function loginWithCode(code: string, state: string): Promise<LoginResponse> {
  return axios.post("/api/msal/login_with_code", {
    code,
    state,
  });
}
