import Vue from "vue";
import Vuex from "vuex";
import { IAuthState } from "@/store/modules/auth";

Vue.use(Vuex);

export interface IRootState {
  auth: IAuthState;
}

// // Create module later in your code (it will register itself automatically)
export default new Vuex.Store<IRootState>({});
