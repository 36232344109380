import { VuexModule, Module, Action, Mutation, getModule } from "vuex-module-decorators";
import { login, refresh, loginWithCode } from "@/services/auth.service";
import { DecodedJWT, LoginRequest, LoginResponse } from "@/types/auth.type";
import store from "@/store";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import router from "@/router";
import { UserModule } from "@/store/modules/user-module";

// bug change token name
const tokenAccessKey = "vue_typescript_admin_access_token";
// const tokenRefreshKey = 'vue_typescript_admin_refresh_token';
const getToken = (key: string) => Cookies.get(key);
const setToken = (key: string, token: string) => Cookies.set(key, token);
const removeToken = (key: string) => Cookies.remove(key);

export interface IAuthState {
  token: string;
}

@Module({
  dynamic: true,
  store,
  name: "auth",
})
class Auth extends VuexModule implements IAuthState {
  get hasToken() {
    return this.token !== "";
  }

  get accessToken() {
    return this.token;
  }

  get needRefresh() {
    const payload: DecodedJWT = jwtDecode(this.token);
    return Date.now() - payload.exp * 1000 <= 300; // the token has less then 5 minutes until expire.
  }

  public token = getToken(tokenAccessKey) || "";

  @Action({ rawError: true })
  public async login(req: LoginRequest) {
    const res: LoginResponse = await login(req);
    this.SET_TOKEN(res.token);
    router.push({ name: "Timesheet" });
  }

  @Action({ rawError: true })
  public async loginWithCode(code: string, state: string) {
    try {
      const res: LoginResponse = await loginWithCode(code, state);
      this.SET_TOKEN(res.token);
      await UserModule.getUserProfile();
      if (!UserModule.userProfile.status) {
        router.push({
          name: "Login",
          params: { errorMessage: "Account suspended. Please contract admin." },
        });
        return;
      }
      router.push({ name: "Timesheet" });
    } catch (e) {
      if (e.response.status === 403) {
        router.push({
          name: "Login",
          params: { errorMessage: e.response.data.detail },
        });
      } else if (e.response.status === 500) {
        router.push({
          name: "Login",
          params: { errorMessage: `unable to login due to internal error` },
        });
      }
    }
  }

  @Action({ rawError: true })
  public logout() {
    Cookies.set("MSAL_STATE", "");
    this.REMOVE_TOKEN();
  }

  @Action({ rawError: true })
  public async refreshAccessToken() {
    const res: unknown = await refresh({ token: this.token });
    this.SET_TOKEN((res as LoginResponse).token);
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
    setToken(tokenAccessKey, token);
  }

  @Mutation
  private REMOVE_TOKEN() {
    this.token = "";
    removeToken(tokenAccessKey);
  }
}

export const AuthModule = getModule(Auth);
