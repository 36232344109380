import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthMiddleware from "../middlewares/AuthMiddleware";
import PermissionViewMiddleware from "@/middlewares/PermissionViewMiddleware";
import store from "@/store";
import { Context, Middleware } from "@/types/middleware.type";
import { UserModule } from "@/store/modules/user-module";
import { AuthModule } from "@/store/modules/auth";
import NotFound from "@/components/NotFound.vue";

Vue.use(VueRouter);
const routes: RouteConfig[] = [
  {
    path: "",
    name: "Layout",
    redirect: { name: "Timesheet" },
    component: () => import("@/layouts/Layout.vue"),
    meta: {
      auth: true,
    },
    children: [
      // in app component
      {
        path: "/timesheet/",
        name: "Timesheet",
        component: () => import("@/views/TimesheetList.vue"),
      },
      {
        path: "/reports/",
        name: "Reports",
        component: () => import("@/views/Reports.vue"),
      },
      {
        path: "/project/",
        name: "Project List",
        component: () => import("@/views/project/ProjectList.vue"),
      },
      {
        path: "/project/:id/",
        name: "Project Detail",
        component: () => import("@/views/project/detail/ProjectDetail.vue"),
        props: true,
      },
      {
        path: "/project/:id/setting/",
        name: "Edit Project",
        component: () => import("@/views/project/ProjectSetting.vue"),
        props: true,
      },
      {
        path: "/project/:id/member/",
        name: "New Member",
        component: () => import("@/views/project/member/NewMember.vue"),
        props: true,
      },
      {
        path: "/project/:id/member/:uid/",
        name: "Edit Member",
        component: () => import("@/views/project/member/NewMember.vue"),
        props: true,
      },
      {
        path: "/project/:id/quotation/",
        name: "Quotation",
        component: () => import("@/views/project/quotation/QuotationList.vue"),
        props: true,
      },
      {
        path: "/project/:id/quotation/:uid/",
        name: "Quotation Detail",
        component: () => import("@/views/project/quotation/QuotationDetail.vue"),
        props: true,
      },
      {
        path: "/client/",
        name: "Clients",
        component: () => import("@/views/ClientList.vue"),
        meta: {},
      },
      {
        path: "/project-detail/",
        name: "User",
        component: () => import("@/views/project/detail/ProjectDetail.vue"),
        props: true,
      },
      {
        path: "/users/",
        name: "Users",
        component: () => import("@/views/user/Users.vue"),
        props: true,
        meta: {},
      },
      {
        path: "/users/:id/",
        name: "Edit User",
        component: () => import("@/views/user/EditUser.vue"),
        props: true,
        meta: {},
      },
      {
        path: "/download-report/",
        name: "Download Report",
        component: () => import("@/views/export-report/DownloadReport.vue"),
      },
      {
        path: "/day-off",
        name: "Day Off",
        component: () => import("@/views/day-off/DayOff.vue"),
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/OverviewReportDashboard.vue"),
      },
      {
        path: "/admin-setting",
        name: "Admin Setting",
        component: () => import("@/views/AdminSetting.vue"),
      },
      {
        path: "/leave-request",
        name: "Leave Request",
        component: () => import("@/views/leave-requests/Requests.vue"),
      },
      {
        path: "/leave-request/calendar",
        name: "Leave Request Calendar",
        component: () => import("@/views/leave-requests/LeaveRequestCalendar.vue"),
      },
      {
        path: "/leave-request-admin",
        name: "Leave Request Admin",
        component: () => import("@/views/leave-requests/LeaveRequestAdmin.vue"),
      },
      {
        path: "/leave-requests-admin/:id/",
        name: "Approve / Reject leave request",
        component: () => import("@/views/leave-requests/LeaveRequestApproval.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/login/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/msal-callback/",
    name: "Msal Callback",
    component: () => import("@/views/MsalCallback.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

routes.forEach((route) => {
  const middleWares = {
    middleware: [AuthMiddleware, PermissionViewMiddleware],
  };
  if (route.name === "Layout") {
    route.meta = { ...route.meta, middleWares };
    if (route.children) {
      route.children.forEach((child) => {
        if (child.meta) {
          child.meta = middleWares;
        }
      });
    }
  }
});

const router = new VueRouter({
  routes,
  mode: "history",
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context: Context, middleware: [Middleware], index: number) {
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (index >= middleware.length) {
    return context.next;
  } // if typeof middleware != [Middleware]

  const subsequentMiddleware = middleware[index];
  return (...parameters: any[]) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    const nexContext: Context = context;
    nexContext.next = nextMiddleware;
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
router.beforeEach(async (to, from, next) => {
  const auth: boolean = to.matched.some((route) => route.meta.auth);
  if (AuthModule.hasToken && Object.keys(UserModule.userProfile).length === 0 && auth) {
    await UserModule.getUserProfile();
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context: Context = {
      from,
      next,
      router,
      to,
      store,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});

export default router;
