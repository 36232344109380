import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { getLeaveRequestCountAll } from "@/services/leave-request.service";
import { getProjectNotificationCount } from "@/services/project.service";

@Module({
  dynamic: true,
  name: "notification",
  store,
})
class Notification extends VuexModule {
  public leaveRequestCount: number = 0;
  public projectNotificationCount: number = 0;

  @MutationAction({ mutate: ["leaveRequestCount"], rawError: true })
  public async getLeaveRequestCount() {
    const leaveRequestCount: any = await getLeaveRequestCountAll();
    return { leaveRequestCount: Math.min(leaveRequestCount.count, 99) };
  }

  @MutationAction({ mutate: ["projectNotificationCount"], rawError: true })
  public async getProjectNotificationCount() {
    const projectNotificationCount: any = await getProjectNotificationCount();
    return { projectNotificationCount: Math.min(projectNotificationCount.count, 99) };
  }
}

export const NotificationModule = getModule(Notification);
