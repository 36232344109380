import axios from "@/plugins/axios-setting";
import {
  LeaveReasonInterface,
  LeaveRequestAllInterface,
  LeaveRequestInterface,
  LeaveRequestPaginationInterface,
  LeaveRequestUpdateInterface,
} from "@/types/leave-request.type";
import { DataTableOption } from "@/types/data-table.type";
import { formatOrdering } from "@/utils/datatableUtils";

export function getLeaveReasons(): Promise<LeaveReasonInterface[]> {
  return axios.get("/api/leave-requests/reasons/");
}

export function getLeaveCount(createdBy: number, reasonId: number): Promise<any> {
  return axios.get(`/api/leave-requests/requests/?created_by=${createdBy}&reason=${reasonId}&approved=true`);
}

export function getAllLeaveCount(userId: number, year: number): Promise<any> {
  return axios.get(`/api/leave-requests/requests-count/?year=${year}&userId=${userId}`);
}

export function getLeaveCountPending(createdBy: number, reasonId: number): Promise<any> {
  return axios.get(`/api/leave-requests/requests/?created_by=${createdBy}&reason=${reasonId}&null_approved_by=true`);
}

export function getLeaveCountRejected(createdBy: number, reasonId: number): Promise<any> {
  return axios.get(
    `/api/leave-requests/requests/?created_by=${createdBy}&reason=${reasonId}&approved=false&null_approved_by=false`
  );
}

export function addLeaveRequest(request: LeaveRequestInterface): Promise<LeaveReasonInterface[]> {
  return axios.post("/api/leave-requests/requests/", request);
}

export function updateLeaveRequest(request: LeaveRequestUpdateInterface): Promise<LeaveRequestInterface> {
  return axios.patch(`/api/leave-requests/requests/${request.id}/`, request);
}

export function editLeaveRequest(request: LeaveRequestInterface): Promise<LeaveRequestInterface> {
  return axios.patch(`/api/leave-requests/requests/${request.id}/`, request);
}

export function getLeaveRequest(id: number): Promise<LeaveRequestInterface> {
  return axios.get(`/api/leave-requests/requests/${id}/`);
}

export function filterLeaveRequests(
  createdBy: number,
  reasonId: number,
  status: string,
  startDate: string,
  endDate: string,
  options?: DataTableOption
): Promise<LeaveRequestPaginationInterface> {
  const newOptions = new URLSearchParams();
  if (options) {
    newOptions.append("itemsPerPage", String(options.itemsPerPage));
    newOptions.append("page", String(options.page));
    newOptions.append("ordering", formatOrdering(options));
  }

  switch (status) {
    case "Approved":
      newOptions.append("approved", "true");
      break;
    case "Pending":
      newOptions.append("null_approved_by", "true");
      break;
    case "Rejected":
      newOptions.append("null_approved_by", "false");
      newOptions.append("approved", "false");
      break;
  }

  newOptions.append("created_by", String(createdBy));
  if (reasonId !== -1) {
    newOptions.append("reason", String(reasonId));
  }
  newOptions.append("date_after", startDate);
  newOptions.append("date_before", endDate);
  return axios.get("/api/leave-requests/requests/", { params: newOptions });
}

export function getAllLeaveRequests(createdBy: number): Promise<LeaveRequestPaginationInterface> {
  return axios.get(`/api/leave-requests/requests/?created_by=${createdBy}`);
}

export function deleteLeaveRequests(id: number): Promise<LeaveRequestInterface[]> {
  return axios.delete(`/api/leave-requests/requests/${id}/`);
}

export function getPendingLeaveRequestService(options?: DataTableOption): Promise<LeaveRequestPaginationInterface> {
  const newOptions = new URLSearchParams();
  if (options) {
    newOptions.append("itemsPerPage", String(options.itemsPerPage));
    newOptions.append("page", String(options.page));
    newOptions.append(
      "ordering",
      formatOrdering(options, {
        date: "start_date",
      })
    );
  }

  newOptions.append("null_approved_by", "true");
  return axios.get("/api/leave-requests/requests/", { params: newOptions });
}

export function getHistoryLeaveRequestService(options?: DataTableOption): Promise<LeaveRequestPaginationInterface> {
  const newOptions = new URLSearchParams();

  if (options) {
    newOptions.append("itemsPerPage", String(options.itemsPerPage));
    newOptions.append("page", String(options.page));
    newOptions.append(
      "ordering",
      formatOrdering(options, {
        date: "start_date",
      })
    );
  }

  newOptions.append("null_approved_by", "false");
  return axios.get("/api/leave-requests/requests/", { params: newOptions });
}

export function getLeaveRequestCountAll(): Promise<any> {
  return axios.get("/api/leave-requests/count-all/");
}

export function getLeaveRequestByMonth(startDate: string, endDate: string): Promise<LeaveRequestAllInterface[]> {
  const url = `/api/leave-requests/all-requests/?date_after=${startDate}&date_before=${endDate}`;
  return axios.get(url);
}
