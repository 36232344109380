import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { FullDetailUserInterface, UserInterface } from "@/types/user.type";
import { getUsers, getUserProfile } from "@/services/user.service";
import { NotificationModule } from "@/store/modules/notification-module";
import { ProjectModule } from "@/store/modules/project-module";

@Module({
  dynamic: true,
  name: "user",
  store,
})
class User extends VuexModule {
  public users: UserInterface[] = [];
  public userProfile: FullDetailUserInterface = {} as FullDetailUserInterface;

  get isAdmin() {
    return this.userProfile.role.name === "Admin";
  }

  get isEmployee() {
    return this.userProfile.role.name === "Employee";
  }

  public get isGuest(): boolean {
    return this.userProfile.role.name.toLowerCase() === "guest";
  }

  @MutationAction({ mutate: ["users"], rawError: true })
  public async getUsers({ noProject = true, noName = true }: { noProject?: boolean; noName?: boolean } = {}) {
    const paramStr = `?no_project=${noProject}&no_name=${noName}`;
    const users: UserInterface[] = await getUsers(paramStr);
    return { users };
  }

  @Action({ rawError: true })
  public async getUserProfile() {
    const profile: FullDetailUserInterface = await getUserProfile();
    await ProjectModule.getUserProjects();
    if (profile.role.name.toLowerCase() === "admin") {
      await NotificationModule.getLeaveRequestCount();
    }
    await NotificationModule.getProjectNotificationCount();
    this.SET_USE_PROFILE(profile);
  }

  @Mutation
  private SET_USE_PROFILE(userProfile: FullDetailUserInterface) {
    this.userProfile = userProfile;
  }
}

export const UserModule = getModule(User);
