import axios from "axios";
import axiosRetry, { IAxiosRetryConfig } from "axios-retry";
import router from "@/router";
import { AuthModule } from "@/store/modules/auth";
import { ErrorModule } from "@/store/modules/error";

const axiosInstance = axios.create();
if (process.env.VUE_APP_BACKEND_URL) {
  axiosInstance.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
} else {
  axiosInstance.defaults.baseURL = "http://localhost:8000";
}

axiosInstance.interceptors.request.use(
  async (config) => {
    if (AuthModule.hasToken) {
      config.headers.common.Authorization = `JWT ${AuthModule.accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.headers["content-type"] === "application/json") {
      return response.data;
    }
    return response;
  },
  (error) => {
    const response = error.response;
    if (response.status === 401) {
      // invalid token or token has already expired
      router.push({ name: "Login" });
      AuthModule.logout();
    }
    if (response.status === 503) {
      router.push({
        name: "Login",
        params: {
          errorMessage: "Server is under maintenance. Please try again later.",
          alertType: "info",
        },
      });
    }
    if (
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }
    if (response.status === 404) {
      ErrorModule.errorNotFound(true);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
