import axios from "@/plugins/axios-setting";
import { FullDetailUserInterface, RoleInterface, UserInterface, UserProject } from "@/types/user.type";

export function getUserProject(id: string): Promise<UserProject[]> {
  return axios.get(`/api/user-details/${id}/projects/`);
}

export function getUserProfile(): Promise<FullDetailUserInterface> {
  return axios.get("/api/whoami/");
}

export function getUsers(param: string): Promise<UserInterface[]> {
  return axios.get(`/api/user/${param}`);
}

export function getUserById(id: string): Promise<FullDetailUserInterface> {
  return axios.get(`/api/user-details/${id}/`);
}

export function getAllUsers(): Promise<FullDetailUserInterface[]> {
  return axios.get("/api/user-details/");
}

export function getRoles(): Promise<RoleInterface[]> {
  return axios.get("/api/role/");
}

export function updateUserDetails(id: string, body: any) {
  return axios.patch(`/api/user-details/${id}/`, body);
}

export function resetLeaveLimits(body: { userIds: string[] }) {
  return axios.patch(`/api/leave-limits/`, body);
}
