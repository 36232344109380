import { Context } from "@/types/middleware.type";
import { UserModule } from "@/store/modules/user-module";
import { checkPermission } from "@/utils/permission";

export default async function PermissionViewMiddleware(context: Context) {
  const user = UserModule.userProfile;
  const allow = checkPermission(user, context.to.name);
  if (allow) {
    return context.next();
  } else {
    return context.router.push({ name: "Timesheet" });
  }
}
