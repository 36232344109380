import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { SnackbarInterface } from "@/types/snackbar";

@Module({
  dynamic: true,
  name: "snackbar",
  store,
})
class Snackbar extends VuexModule {
  public snack: SnackbarInterface = {
    color: "success",
    message: "",
  };

  @MutationAction({ mutate: ["snack"] })
  public async setSnack(snack: SnackbarInterface) {
    return { snack };
  }
}

export const SnackbarModule = getModule(Snackbar);
